import ProductCard from "@/components/cards/ProductCard";
import useScreenSize from "@/hooks/display/useDisplay";

import { useRouter } from "next/router";
import { Swiper, SwiperSlide } from "swiper/react";

const ProductsSwiper = ({ set_swiper, products }) => {
  const { locale } = useRouter();
  const { screenWidth } = useScreenSize();
  const isMobile = screenWidth < 600;
  const isTablet = screenWidth <= 768;
  return (
    <Swiper
      key={locale}
      dir={locale === "en" ? "ltr" : "rtl"}
      // modules={[Autoplay]}
      onInit={(e) => set_swiper(e)}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      // breakpoints={{
      //   320: {
      //     spaceBetween: 10,
      //     slidesPerView: 2.3,
      //   },
      //   769: {
      //     spaceBetween: 10,
      //     slidesPerView: 3,
      //   },
      //   1023: {
      //     spaceBetween: 10,
      //     slidesPerView: 4.5,
      //   },
      //   1581: {
      //     spaceBetween: 10,
      //     slidesPerView: 6,
      //   },
      // }}
      loop
      spaceBetween={!isMobile ? 16 : 3}
      slidesPerView={isMobile ? 2.5 : isTablet ? 3.5 : 5}
      className={`h-full w-[${screenWidth}px]`}
    >
      {products.map((product) => (
        <SwiperSlide
          key={
            typeof product?.sku == "object" ? product?.sku?.at(0) : product?.sku
          }
          className="sm:max-w-[230px]"
        >
          <ProductCard product={product} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductsSwiper;
