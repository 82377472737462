import { useEffect, useMemo, useState } from "react";
import ProductsSwiper from "../global/swiper/ProductsSwiper";
import SwiperButtons from "../global/buttons/SwiperButtons";
import MultiButtons from "../global/buttons/MultiButtons";
import Time from "./Time";
import { countDownHandler } from "@/utils/global";
import { getCookies } from "cookies-next";
import useSWRMutation from "swr/mutation";
import { getProductsByCategoryId } from "@/services/category";
import { useSWRConfig } from "swr";

const Products = ({ t, locale, isSwiper, isMulti, data }) => {
  const [swiper, set_swiper] = useState({});
  const [currentTab, setCurrentTab] = useState("");
  const { handshake } = getCookies();

  const { trigger, data: tabProducts } = useSWRMutation(
    `/tab/${currentTab}/${locale}`,
    (_, { arg: tab }) =>
      getProductsByCategoryId(
        handshake,
        tab,
        "most viewed",
        "DESC",
        12,
        0,
        false
      ),
    { populateCache: true }
  );

  const { cache } = useSWRConfig();

  useEffect(() => {
    const currentTabCache = cache.get(`/tab/${currentTab}/${locale}`);

    if (currentTab && !currentTabCache) {
      trigger(currentTab);
    }
  }, [currentTab, trigger, cache, locale]);

  const changeTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const products = useMemo(() => {
    const activeData = data?.value?.filter((item) => item?.active);
    const currentTabCache = cache.get(`/tab/${currentTab}/${locale}`);

    const displayedProducts =
      currentTabCache?.data?.data?.data?.products?.length > 0
        ? currentTabCache?.data?.data?.data?.products
        : tabProducts?.data?.data?.products?.length > 0
        ? tabProducts?.data?.data?.products
        : activeData?.at(0)?.products || [];

    return displayedProducts;
  }, [
    data?.value,
    tabProducts?.data?.data?.products,
    cache,
    currentTab,
    locale,
  ]);

  return (
    data?.active &&
    data?.value?.length > 0 && (
      <section className="mx-auto">
        <div
          className={`flex items-center justify-between mb-3 sm:mb-4 ${
            isMulti ? "flex-wrap gap-4" : ""
          }`}
        >
          <div className="flex flex-wrap items-center gap-4">
            <span className="text-lg font-extrabold sm:text-xl">
              {locale === "ar" ? data?.["title-ar"] : data?.["title-en"]}
            </span>
            {data?.counter &&
            countDownHandler(data?.counter?.["date-to"])?.isCountDown ? (
              <Time t={t} counter={data?.counter} />
            ) : null}
          </div>
          <div className="flex gap-4 overflow-x-auto">
            {isMulti && (
              <MultiButtons onChangeTab={changeTabHandler} data={data?.value} />
            )}
            {isSwiper && <SwiperButtons {...{ swiper, locale }} />}
          </div>
        </div>
        <ProductsSwiper products={products} set_swiper={set_swiper} />
      </section>
    )
  );
};

export default Products;
