// import Banners from "@/components/home/Banners";
import Banners from "@/components/home/Banners";
import Brands from "@/components/home/Brands";
import Categories from "@/components/home/Categories";
import Gifts from "@/components/home/Gifts";
import Products from "@/components/home/Products";
import StaticBanners from "@/components/home/StaticBanners";
import { getProductsByCategoryId } from "@/services/category";

export const getTabsData = async (handshake, tabs) => {
  if ("value" in tabs) {
    const firstTab = await getProductsByCategoryId(
      handshake,
      tabs?.value?.at(0)?.id,
      "",
      "",
      12,
      0,
      false
    );
    const tabsProducts = tabs.value.map((tab, idx) =>
      idx === 0
        ? {
            ...tab,
            products: firstTab.data?.data?.products || [],
          }
        : { ...tab }
    );
    return { ...tabs, value: tabsProducts };
  }
  return tabs;
};

export const renderHomeSection = (
  sectionType,
  data,
  t,
  locale,
  push,
  isMobile
) => {
  switch (sectionType) {
    case "first-section":
      return (
        <section key={data?.position} className="mx-auto ">
          <Banners {...{ data, locale, isMobile }} />
        </section>
      );
    case "categories":
      return (
        <Categories
          key={data?.position}
          {...{
            data,
          }}
        />
      );
    case "products-list":
    case "today-offers":
      return (
        <Products
          key={data?.position}
          {...{
            isMulti: data?.value?.length > 1,
            isSwiper: data?.value?.length >= 1,
            data,
            locale,
            t,
            sectionType,
          }}
        />
      );
    case "brands":
      return <Brands key={data?.position} {...{ data, locale, t }} />;
    case "two-banners":
      return (
        <section key={data?.position} className="mx-auto ">
          <StaticBanners {...{ data, locale, push }} />
        </section>
      );
    case "gifts":
      return (
        <section key={data?.position} className="mx-auto ">
          <Gifts {...{ data, locale }} />
        </section>
      );
    default:
      return null;
  }
};
