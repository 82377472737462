import SwiperButtons from "../global/buttons/SwiperButtons";
import Image from "@/components/global/Image";
import Link from "next/link";

import { useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Brands = ({ data, locale }) => {
  const [swiper, set_swiper] = useState({});
  const title =
    locale === "ar"
      ? "title-ar" in data
        ? data["title-ar"]
        : ""
      : "title-en" in data
      ? data["title-en"]
      : "";

  const activeData = data?.value?.filter((item) => item?.active);

  return (
    data?.active &&
    activeData?.length > 0 && (
      <section className="mx-auto">
        <div className="flex items-center justify-between mb-3 sm:mb-4">
          <span className="text-lg sm:text-xl font-extrabold">{title}</span>
          <SwiperButtons {...{ swiper, locale }} />
        </div>
        <Swiper
          key={locale}
          dir={locale === "en" ? "ltr" : "rtl"}
          onInit={(e) => set_swiper(e)}
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          breakpoints={{
            320: {
              spaceBetween: 5,
              slidesPerView: 3.5,
            },
            640: {
              spaceBetween: 20,
              slidesPerView: 5,
            },
            769: {
              spaceBetween: 20,
              slidesPerView: 7,
            },
            1024: {
              spaceBetween: 20,
              slidesPerView: 9,
            },
          }}
        >
          {activeData?.map(
            (brand, idx) =>
              brand?.active && (
                <SwiperSlide key={idx}>
                  <Link href={brand.url || "/"}>
                    <Image
                      src={brand["img-" + locale]}
                      className="object-contain w-full h-20 border sm:h-28 p-3"
                      alt={brand["title-" + locale] || "Brand"}
                    />
                  </Link>
                </SwiperSlide>
              )
          )}
        </Swiper>
      </section>
    )
  );
};

export default Brands;
