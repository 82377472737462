import Image from "@/components/global/Image";
import { handleLinkByType } from "@/helpers/common/handleLinkByType";
import Link from "next/link";
import { useRouter } from "next/router";

const Gifts = ({ data, locale }) => {
  const activeData = data?.value?.filter((item) => item?.active);
  const { push } = useRouter();
  return (
    data?.active &&
    activeData?.length > 0 && (
      <section className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {activeData?.map((gift, idx) => (
          <div key={idx} className="cursor-pointer">
            <Link
              href={handleLinkByType(gift?.url, gift?.type)}
              className="mt-3 mb-0.5 sm:mb-1 space-y-4 text-lg sm:text-xl font-semibold"
            >
              <Image
                onClick={() => push(handleLinkByType(gift?.url, gift?.type))}
                src={gift["img-" + locale]}
                className="object-cover w-full h-auto rounded-md min-h-56 sm:min-h-80 cursor-pointer"
                alt="Img !"
              />
                <h2>{gift["title-" + locale] || ""}</h2>
              <p className="text-xs max-sm:leading-5 text-zinc-400 sm:text-sm font-[500]">
                {gift["desc-" + locale] || ""}
              </p>
            </Link>
          </div>
        ))}
      </section>
    )
  );
};

export default Gifts;
