import { useRouter } from "next/router";
import { useState } from "react";

const MultiButtons = ({ data, onChangeTab }) => {
  const { locale } = useRouter();
  const [active, setActive] = useState(data?.[0]?.id);

  return (
    <div className="flex items-center gap-2 overflow-x-auto sm:gap-4">
      {data?.map((tab, idx) => (
        <button
          key={idx}
          onClick={() => {
            setActive(tab?.id);
            onChangeTab(tab?.id);
          }}
          className={`py-2 font-semibold ${
            active === tab?.id ? "text-white bg-red-600 border-red-600" : ""
          } border rounded-sm min-w-32 max-sm:text-sm`}
        >
          {tab["title-" + locale]}
        </button>
      ))}
    </div>
  );
};

export default MultiButtons;
