import { countDownHandler } from "@/utils/global";
import { useEffect, useState } from "react";

const Time = ({ t, counter }) => {
  const [countDown, setCountDown] = useState(
    countDownHandler(counter?.["date-to"])
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown(countDownHandler(counter?.["date-to"]));
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <>
      {countDown.isCountDown && (
        <div className="items-center gap-2 flex max-sm:text-xs">
          <span className="px-4 max-sm:px-2 py-1 border border-red-600 rounded-md">
            {countDown.days} {t("Days")}
          </span>
          <span className="text-xl text-red-600">:</span>
          <span className="px-4 max-sm:px-2 py-1 border border-red-600 rounded-md">
            {countDown.hours} {t("Hours")}
          </span>
          <span className="text-xl text-red-600">:</span>
          <span className="px-4 max-sm:px-2 py-1 border border-red-600 rounded-md">
            {countDown.minutes} {t("Minutes")}
          </span>
        </div>
      )}
    </>
  );
};

export default Time;
