import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
const Seo = ({ title, description, image = null}) => {
  const metadata = {
    ar:{
      backcomfort:'باك كمفورت',
      title:'تسوق من باك كمفورت',
      desc:'تسوق من باك كمفورت الشركة الرائدة فى عالم كراسى الراحة والاسترخاء، كراسى المساج، كراسى البر والرحلات، كراسى المكتب، افضل مراتب النوم ومنتجات اللياقة البدنية ومنتجات الاطفال.'
    },
    en:{
      backcomfort:'Back Comfort',
      title:'Shop from Back Comfort',
      desc:'Shop from Back Comfort, The leader in the world of recliners and relaxation chairs, massage chairs, outdoor chairs, office chairs, the best sleeping mattresses, fitness, and children\'s products.'
    }
  }
  const {locale, asPath} = useRouter()
  const img = image || `${process.env.NEXT_PUBLIC_WEBSITE_URL}/backcomfort.webp`
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}${locale === 'ar' ? '' : '/en'}${asPath === '/' ? '' : asPath}`;
  return (
    <Head>
      <title>{title ? `${title} | ${metadata[locale]?.backcomfort}` : metadata[locale]?.title}</title>
      <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
          locale === "en" ? "/en" : ""
        }${asPath}`}
      />
      <meta name="title" content={title || metadata[locale]?.title} />
      <meta
        name="description"
        content={description || metadata[locale]?.desc}
      />

      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={url || process.env.NEXT_PUBLIC_WEBSITE_URL}
      />
      <meta property="og:title" content={title || metadata[locale]?.title} />
      <meta
        property="og:description"
        content={description || metadata[locale]?.desc}
      />
      <meta property="og:image" content={img} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={url || process.env.NEXT_PUBLIC_WEBSITE_URL}
      />
      <meta property="twitter:title" content={title || metadata[locale]?.title} />
      <meta
        property="twitter:description"
        content={description || metadata[locale]?.desc}
      />
      <meta property="twitter:image" content={img} />
    </Head>
  );
};

export default Seo;
