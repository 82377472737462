import { handleLinkByType } from "@/helpers/common/handleLinkByType";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Link from "next/link";
import Image from "@/components/global/Image";
import MainSliderCard from "../cards/MainSliderCard";

const Banners = ({ data, locale, isMobile }) => {
  const activeSlider = data?.sliders?.value?.filter((item) => item?.active);
  const activeBanner = data?.banners?.value.filter((item) => item?.active);

  const imgSrcHandler = (image) => {
    const img = image?.[`${isMobile ? "mobile-" : ""}img-${locale}`];
    return img && img.startsWith("http")
      ? img
      : `${process.env.NEXT_PUBLIC_IMG_BASEURL}/json/${img}`;
  };

  return (
    <section className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        key={locale}
        pagination={{ clickable: true }}
        dir={locale === "en" ? "ltr" : "rtl"}
        className="w-full banner-swiper"
        loop
      >
        {activeSlider?.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <MainSliderCard slider={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="flex gap-2 overflow-x-auto sm:gap-4 red-scroll pb-1 sm:grid sm:grid-cols-2">
        <div className="flex items-center justify-between gap-2 sm:gap-4 max-[640px]:max-w-96 sm:flex-col">
          <Link
            href={handleLinkByType(
              activeBanner?.at(0)?.url,
              activeBanner?.at(0)?.type
            )}
            className="relative group max-w-[309px]"
          >
            <Image
              src={imgSrcHandler(activeBanner?.at(0))}
              className="object-cover w-full max-sm:min-w-40 h-auto max-h-[233px] max-sm:min-h-40 max-sm:max-h-40 md:min-h-[440px]"
              loading="eager"
              alt=""
            />
            <HoverBanner subtitle={activeBanner?.at(0)?.[`title-${locale}`]} />
          </Link>
          <Link
            href={handleLinkByType(
              activeBanner?.at(1)?.url,
              activeBanner?.at(1)?.type
            )}
            className="relative group max-w-[309px]"
          >
            <Image
              src={imgSrcHandler(activeBanner?.at(1))}
              className="object-cover w-full max-sm:min-w-40 h-auto max-sm:min-h-40 max-sm:max-h-40 md:min-h-[233px]"
              loading="eager"
              alt=""
            />
            <HoverBanner subtitle={activeBanner?.at(1)?.[`title-${locale}`]} />
          </Link>
        </div>
        <div className="flex items-center justify-between gap-2 sm:gap-4 max-[640px]:max-w-96 sm:flex-col">
          <Link
            href={handleLinkByType(
              activeBanner?.at(2)?.url,
              activeBanner?.at(2)?.type
            )}
            className="group relative max-w-[309px]"
          >
            <Image
              src={imgSrcHandler(activeBanner?.at(2))}
              className="object-cover w-full max-sm:min-w-40 h-auto max-sm:min-h-40 max-sm:max-h-40 md:min-h-[233px]"
              loading="eager"
              alt=""
            />
            <HoverBanner subtitle={activeBanner?.at(2)?.[`title-${locale}`]} />
          </Link>
          <Link
            href={handleLinkByType(
              activeBanner?.at(3)?.url,
              activeBanner?.at(3)?.type
            )}
            className="relative group max-w-[309px]"
          >
            <Image
              src={imgSrcHandler(activeBanner?.at(3))}
              className="object-cover w-full max-sm:min-w-40 h-auto max-sm:min-h-40 max-sm:max-h-40 md:min-h-[440px]"
              loading="eager"
              alt=""
            />
            <HoverBanner subtitle={activeBanner?.at(3)?.[`title-${locale}`]} />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Banners;

const HoverBanner = ({ title = "", subtitle = "", description = "" }) => {
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-full transition-all duration-500 bg-black opacity-0 group-hover:opacity-100 bg-opacity-60">
      <div className="flex flex-col justify-center h-full gap-1 text-sm text-white sm:text-lg sm:gap-2 ms-2 sm:ms-10">
        <span>{subtitle}</span>
        <h1 className="text-lg font-bold md:text-xl text-primary">{title}</h1>
        <span>{description}</span>
      </div>
    </div>
  );
};
