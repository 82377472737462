import Link from "next/link";
import Image from "@/components/global/Image";
import { handleLinkByType } from "@/helpers/common/handleLinkByType";

const StaticBanners = ({ data, locale }) => {
  const activeData = data?.value?.filter((item) => item?.active);
  return (
    data?.active &&
    activeData?.length > 0 && (
      <section className="flex items-center justify-center gap-1 sm:gap-4">
        {activeData?.map((banner, idx) => {
          const imgSrc = banner["img-" + locale]?.includes("https://")
            ? banner["img-" + locale]
            : `${process.env.NEXT_PUBLIC_IMG_BASEURL}/json/${
                banner["img-" + locale]
              }`;

          const img = imgSrc?.includes("banner-4-")
            ? imgSrc?.replace(".jpg", ".png")
            : imgSrc;
          return (
            <Link
              href={handleLinkByType(banner?.url, banner?.type)}
              key={idx}
              className="h-full w-auto relative overflow-hidden rounded-md"
            >
              <Image
                src={img}
                loading="lazy"
                className="object-cover w-full h-auto transition-all duration-300 rounded-md cursor-pointer hover:scale-105"
                alt={banner["title-" + locale] || "Banner"}
              />
            </Link>
          );
        })}
      </section>
    )
  );
};

export default StaticBanners;
