import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { getHomeJson } from "@/services/home";
import Seo from "@/components/seo";
import { getTabsData, renderHomeSection } from "@/utils/home";
import { useRouter } from "next/router";
import { fetchHandshake } from "@/services/handshake";

const HomePage = ({ home, isMobile }) => {
  const { locale, push } = useRouter();
  const { t } = useTranslation("home");
  return (
    <>
      <Seo title={locale === "en" ? "Home" : "الرئيسية"} />
      <section className="*:my-3 *:sm:my-8 subMainSize">
        {home?.length
          ? home.map((section) =>
              section?.active
                ? renderHomeSection(
                    section.key,
                    section,
                    t,
                    locale,
                    push,
                    isMobile
                  )
                : null
            )
          : null}
      </section>
    </>
  );
};

export default HomePage;

export async function getServerSideProps({ locale, req, res }) {
  // get handshake from cookies or generate new handshake
  const handshake = await fetchHandshake({ req, res, locale });
  const userAgent = req.headers["user-agent"] || "";
  const isMobile =
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      userAgent
    );

  // get home page structure json
  const { data, error } = await getHomeJson(handshake);
  console.log(data?.data)
  if (error) {
    return {
      props: {},
    };
  }
  const homeJson = Array.isArray(data?.data) ? data?.data?.flat() : [];
  // render first section (main banner and main slider)
  const mainBanners =
    homeJson?.find((item) => item.key === "main-banners") || {};
  const mainSliders =
    homeJson?.find((item) => item.key === "main-sliders") || {};

  const firstSection =
    mainBanners?.active && mainSliders?.active
      ? {
          position: 1,
          key: "first-section",
          active: true,
          banners: mainBanners,
          sliders: mainSliders,
        }
      : {};

  const static_parts = [...homeJson, firstSection]?.filter(
    (item) =>
      ![
        "products-list",
        "today-offers",
        "main-banners",
        "main-sliders",
      ].includes(item.key)
  );

  const tabs_parts = homeJson
    ?.filter(
      (item) => item.key === "products-list" || item.key === "today-offers"
    )
    ?.map((tab) => ({
      ...tab,
      value: tab.value.sort((a, b) => +a["tab-position"] - +b["tab-position"]),
    }));
  const allTabs = await Promise.all([
    ...tabs_parts?.map((tab) => getTabsData(handshake, tab)),
  ]);
  const home = [
    ...(Array.isArray(static_parts) ? static_parts : []),
    ...(Array.isArray(allTabs) ? allTabs : []),
  ]
    .sort((a, b) => +a.position - +b.position)
    ?.filter((tab) => tab.active);

  return {
    props: {
      isMobile,
      home,
      ...(await serverSideTranslations(locale, ["common", "home"])),
    },
  };
}
